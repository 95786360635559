import React, { FC } from 'react'
import FlatButton from '../../components/flat_button'

type Props = {
  onClick: () => void
}

const MessageSection: FC<Props> = (props) => {
  return (
    <section className="py-20 bg-main-green">
      <div className="w-11/12 max-w-7xl mx-auto lg:flex justify-between items-center">
        {/* 左 */}
        <div className="text-white mb-8 lg:mb-0">
          <h3 className="text-3xl font-bold mb-4 leading-normal">
            今こそ、SDGs 共創 Lodge で<br />
            世界の負の連鎖を終わらせ、
            <br />
            新たなビジネスの可能性を切り開こう。
          </h3>
          <p>
            資料をダウンロードして、持続可能な未来の第一歩を踏み出しましょう。
          </p>
        </div>
        {/* 右 */}
        <FlatButton
          className="!bg-main-black py-2 mx-auto lg:mx-0"
          onClick={() => props.onClick()}
        >
          今すぐ資料をダウンロード
        </FlatButton>
      </div>
    </section>
  )
}

export default MessageSection
