import React, { createRef, RefObject, useState } from 'react'
import FlatButton from '../../components/flat_button'
import Logo from '../../images/logo.png'
import LogoWhite from '../../images/logo_white.png'
import LogoPR from '../../images/logo_pr.png'
import LogoKuchikomi from '../../images/logo_kuchikomi.png'
import TopBG from '../../images/top_bg.png'
import AboutUs from '../../images/aboutus/aboutus_main.png'
import Matching from '../../images/features/matching.png'
import Goal from '../../images/features/goal.png'
import Solution from '../../images/features/solution.png'
import Language from '../../images/features/language.png'
import Message from '../../images/features/message.png'
import OneteamBG from '../../images/oneteam/bg.png'
import ScenarioFlow from './_scenario_flow'
import MessageSection from './_message_section'
import FiveSteps from './_five_steps'
import FacedSection from './_faced_section'

const ADDRESS = '〒650-0035 兵庫県神戸市中央区浪花町56 KiP内'
const TEL = '050-1808-4630'
const MAIL = 'cs@the-lodges.jp'

const TopPage = () => {
  const topRef = createRef<HTMLDivElement>()
  const featureRef = createRef<HTMLDivElement>()
  const solutionRef = createRef<HTMLDivElement>()
  const fiveStepRef = createRef<HTMLDivElement>()
  const visionRef = createRef<HTMLDivElement>()

  const [openHamMenu, setOpenHamMenu] = useState(false)

  const scrollToRef = (ref: RefObject<HTMLDivElement>) => {
    if (ref.current) {
      const elementPosition =
        ref.current.getBoundingClientRect().top + window.scrollY
      const offsetPosition = elementPosition - 60
      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
      })
      setOpenHamMenu(false)
    }
  }

  const transferFormPage = () => {
    const url = 'https://forms.gle/adLPrUPYo5GHtTbZA'
    window.open(url, '_blank')
  }

  const content = (
    <body>
      {/* ハンバーガーメニュー */}
      <div
        id="ham_btn"
        onClick={() => setOpenHamMenu(!openHamMenu)}
        className={openHamMenu ? 'open' : ''}
      >
        <span></span>
        <span></span>
        <span></span>
        {!openHamMenu && <div className="message">menu</div>}
        {openHamMenu && <div className="message">close</div>}
      </div>

      {/* ハンバーガーメニュー */}
      <div
        className={`w-screen h-screen z-10 bg-main-green fixed top-0 left-0 ${openHamMenu ? 'block' : 'hidden'}`}
      >
        <div className="text-center w-11/12 pt-24 mx-auto">
          <a
            className="block mb-4 text-white font-bold"
            onClick={() => scrollToRef(topRef)}
          >
            TOP
          </a>
          <a
            className="block mb-4 text-white font-bold"
            onClick={() => scrollToRef(featureRef)}
          >
            サービスの特徴
          </a>
          <a
            className="block mb-4 text-white font-bold"
            onClick={() => scrollToRef(solutionRef)}
          >
            課題解決
          </a>
          <a
            className="block mb-4 text-white font-bold"
            onClick={() => scrollToRef(fiveStepRef)}
          >
            共創の5ステップ
          </a>
          <a
            className="block mb-8 text-white font-bold"
            onClick={() => scrollToRef(visionRef)}
          >
            ビジョン
          </a>
          <FlatButton
            className="!bg-main-black mx-auto"
            onClick={() => transferFormPage()}
          >
            今すぐ資料をダウンロード
          </FlatButton>
        </div>
        <img
          src={LogoWhite}
          className="w-40 absolute bottom-8 right-0 left-0 mx-auto"
        />
      </div>

      {/* ヘッダー */}
      <header className="w-full py-2 bg-white hidden lg:block">
        <div className="w-11/12 flex justify-between mx-auto">
          {/* 左 */}
          <div className="flex items-center">
            <img src={Logo} alt="ロゴ" className="h-[60px] block mr-8" />

            <a
              className="mr-8 cursor-pointer"
              onClick={() => scrollToRef(topRef)}
            >
              TOP
            </a>
            <a
              className="mr-8 cursor-pointer"
              onClick={() => scrollToRef(featureRef)}
            >
              サービスの特徴
            </a>
            <a
              className="mr-8 cursor-pointer"
              onClick={() => scrollToRef(solutionRef)}
            >
              課題解決
            </a>
            <a
              className="mr-8 cursor-pointer"
              onClick={() => scrollToRef(fiveStepRef)}
            >
              共創の5ステップ
            </a>
            <a
              className="cursor-pointer"
              onClick={() => scrollToRef(visionRef)}
            >
              ビジョン
            </a>
          </div>

          {/* 右 */}
          <FlatButton
            className="bg-main-green my-1"
            onClick={() => transferFormPage()}
          >
            今すぐ資料をダウンロード
          </FlatButton>
        </div>
      </header>

      {/* FV */}
      <div ref={topRef} />
      <section
        className="bg-cover bg-center py-[100px] sm:py-[250px] pb-[60px] sm:pb-[100px] text-white text-center"
        style={{
          backgroundImage: `url(${TopBG})`,
        }}
      >
        <div className="w-11/12 mx-auto max-w-4xl">
          <h3 className="text-xl sm:text-2xl font-bold mb-4">
            サステナブル×AI
          </h3>
          <h1 className="text-2xl xs:text-3xl sm:text-4xl lg:text-5xl font-bold leading-tight mb-6 break-keep">
            平和の貢献と
            <wbr />
            新規事業を
            <wbr />
            両立させる
            <br />
            SDGs 共創 <wbr />
            プラットフォーム
          </h1>
          <div className="leading-snug mb-12 sm:mb-6 break-keep">
            SDGs 共創 Lodgeは、
            <wbr />
            持続可能な
            <wbr />
            未来を
            <wbr />
            目指す企業が
            <wbr />
            集まり、
            <br />
            共創を通じて
            <wbr />
            課題解決と
            <wbr />
            新規事業を
            <wbr />
            創出するための
            <wbr />
            プラットフォームです。
            <br />
            国内外の
            <wbr />
            パートナーと
            <wbr />
            ともに、
            <wbr />
            共に挑戦し、
            <wbr />
            共に成長し、
            <wbr />
            持続可能な
            <wbr />
            未来を
            <wbr />
            築き上げましょう。
          </div>
          <FlatButton
            className="py-2 mx-auto sm:mb-32"
            onClick={() => transferFormPage()}
          >
            今すぐ資料をダウンロード
          </FlatButton>

          <div className="hidden sm:flex">
            <div className="w-1/4 px-1">
              <span
                className="cursor-pointer inline-block text-center pb-1 border-b-2 border-white w-full opacity-75 hover:opacity-100 transition-opacity"
                onClick={() => scrollToRef(featureRef)}
              >
                サービスの特徴
              </span>
            </div>
            <div className="w-1/4 px-1">
              <span
                className="cursor-pointer inline-block text-center pb-1 border-b-2 border-white w-full opacity-75 hover:opacity-100 transition-opacity"
                onClick={() => scrollToRef(solutionRef)}
              >
                課題解決
              </span>
            </div>
            <div className="w-1/4 px-1">
              <span
                className="cursor-pointer inline-block text-center pb-1 border-b-2 border-white w-full opacity-75 hover:opacity-100 transition-opacity"
                onClick={() => scrollToRef(fiveStepRef)}
              >
                共創の5ステップ
              </span>
            </div>
            <div className="w-1/4 px-1">
              <span
                className="cursor-pointer inline-block text-center pb-1 border-b-2 border-white w-full opacity-75 hover:opacity-100 transition-opacity"
                onClick={() => scrollToRef(visionRef)}
              >
                ビジョン
              </span>
            </div>
          </div>
        </div>
      </section>

      {/* About Us */}
      <section className="w-11/12 max-w-7xl mx-auto md:flex py-20">
        {/* 左 */}
        <div className="w-full md:w-[calc(50%+1rem)] lg:pr-8">
          <p className="text-sm mb-4">About Us</p>
          <h2 className="mb-6 text-2xl sm:text-3xl lg:text-4xl font-bold">
            サステナブル経営と
            <br />
            グローバル共創シナリオを
            <br />
            提案をするデータベース
          </h2>
          <div className="mb-8 text-sm leading-relaxed">
            SDGs Lodgesが展開するSDGsに特化したプレスリリースサイト「SDGs PR
            Lodge」および、SDGsクチコミに特化したクチコミサイト「SDGs クチコミ
            Lodge」に蓄積された情報を活用し、貴社のサステナブル経営や新規事業開発に向けたグローバル共創シナリオを提案いたします。
          </div>
          <div className="xs:flex mb-8 md:mb-0">
            <div className="xs:w-1/2 xs:pr-2 mb-8 xs:mb-0">
              <img className="h-[70px] mb-4" src={LogoPR} />
              <p className="text-sm leading-relaxed">
                世の中のSDGs情報を網羅的に整理することを目的としたプレスリリースサイトです。
              </p>
            </div>
            <div className="xs:w-1/2 xs:pl-2">
              <img className="h-[70px] mb-4" src={LogoKuchikomi} />
              <p className="text-sm leading-relaxed">
                世界中の企業・団体が取り組むSDGs活動をクチコミを通じて応援し合うプラットフォームです。
              </p>
            </div>
          </div>
        </div>
        {/* 右 */}
        <div className="w-full md:w-[calc(50%-1rem)] md:pl-6 lg:pl-12">
          <div
            className="w-full h-[300px] xs:h-[400px] lg:h-[500px] xl:h-[600px] bg-center bg-cover"
            style={{
              backgroundImage: `url(${AboutUs})`,
            }}
          ></div>
        </div>
      </section>

      {/* 特徴 */}
      <section className="w-full bg-main-lightgray py-20">
        <div ref={featureRef} />
        <div className="text-center mx-auto max-w-2xl w-11/12 mb-8 md:mb-16">
          <p className="text-sm mb-4">Service features</p>
          <h2 className="text-2xl sm:text-3xl lg:text-4xl mb-6 font-bold break-keep">
            SDGs 共創 Lodgeの
            <wbr />
            サービス特徴
          </h2>
          <p>
            SDGs 共創
            Lodgeは、2030年までに持続可能な開発目標（SDGs）の達成を目指す企業にとって、共創を促進するためのプラットフォームです。国内外の企業が共に課題解決や新規事業創出に取り組むための強力なツールを提供します。
          </p>
        </div>
        <div className="w-11/12 mx-auto max-w-6xl lg:flex">
          <div className="md:flex lg:mb-0 mb-8">
            {/* マッチング */}
            <div className="md:w-[33.33333%] md:px-4 max-w-96 mx-auto mb-12 md:mb-0">
              <h3 className="font-bold text-center mb-2">企業マッチング機能</h3>
              <img src={Matching} className="block mx-auto h-20 mb-4" />
              <p className="text-center text-sm font-bold mb-4">
                AIが最適なパートナーを見つけ出す企業マッチング機能
              </p>
              <div className="text-sm">
                企業が抱える「課題」や「目標」に基づき、AIがデータを総合的に分析し、最適なパートナー企業を提案します。市場性、事業性、そしてSDGsに沿った協力関係を築くための強力なマッチング機能です。この機能により、企業間の相性を最適化し、共創プロジェクトの成功確率を大幅に向上させます。
              </div>
            </div>
            {/* 共創シナリオ生成 */}
            <div className="md:w-[33.33333%] md:px-4 max-w-96 mx-auto mb-12 md:mb-0">
              <h3 className="font-bold text-center mb-2">共創シナリオ生成</h3>
              <img src={Goal} className="block mx-auto h-20 mb-4" />
              <p className="text-center text-sm font-bold mb-4">
                具体的な道筋を描く共創シナリオ生成機能
              </p>
              <div className="text-sm">
                AIがマッチングした企業データを基に、具体的な共創シナリオを自動生成します。これにより、共創プロジェクトの立ち上げがスピーディーに進行し、企業間の協力がスムーズに進むようサポートします。SDGs達成に向けた実行可能なシナリオが手に入ることで、プロジェクトが確実に成功へと導かれます。
              </div>
            </div>
            {/* AI提案 */}
            <div className="md:w-[33.33333%] md:px-4 max-w-96 mx-auto mb-12 md:mb-0">
              <h3 className="font-bold text-center mb-2">AI提案</h3>
              <img src={Solution} className="block mx-auto h-20 mb-4" />
              <p className="text-center text-sm font-bold mb-4">
                ナレッジベースに基づいたAI提案機能
              </p>
              <div className="text-sm">
                過去の成功事例やプロジェクトの知見を集約したナレッジベースを活用し、AIが蓄積された情報を分析して次のステップや最適なアクションを提案します。蓄積された情報をもとに、企業はより高い成功率でプロジェクトを進め、持続可能なビジネスを構築するための有益なインサイトを得ることが可能です。
              </div>
            </div>
          </div>
          <div className="md:flex justify-center">
            {/* 多言語対応 */}
            <div className="md:w-[33.33333%] lg:w-[50%] md:px-4 max-w-96 mx-auto mb-12 md:mb-0">
              <h3 className="font-bold text-center mb-2">多言語対応</h3>
              <img src={Language} className="block mx-auto h-20 mb-4" />
              <p className="text-center text-sm font-bold mb-4">
                将来的な多言語対応によるグローバル共創のサポート
              </p>
              <div className="text-sm">
                近い将来に多言語対応を実装予定です。これにより、国内外の企業がより円滑に共創できる環境を提供します。現在は単一言語での対応ですが、多言語化が実現すれば、翻訳支援を活用して言語の壁を越え、異なる文化や市場で活動する企業同士が、より広範な地域で共創を行うことが可能となります。
              </div>
            </div>
            {/* 無制限メッセージ */}
            <div className="md:w-[33.33333%] lg:w-[50%] md:px-4 max-w-96 mx-auto mb-12 md:mb-0">
              <h3 className="font-bold text-center mb-2">無制限メッセージ</h3>
              <img src={Message} className="block mx-auto h-20 mb-4" />
              <p className="text-center text-sm font-bold mb-4">
                無制限オファー機能でビジネス機会を最大化
              </p>
              <div className="text-sm">
                パートナー候補に制限なくオファーを送ることができ、複数の企業と同時に共創プロジェクトを進めることが可能です。この仕組みを活用することで、パートナーシップを拡充し、ビジネス機会を大幅に広げることが期待できます。多様な企業との協力を通じて、さらに幅広い事業展開を実現できる強力なツールです。
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* こんな課題を抱えていませんか？ */}
      <section className="mx-auto w-11/12 max-w-7xl py-20">
        <div ref={solutionRef} />
        <div className="mb-12">
          <p className="text-sm mb-4">Challenges faced</p>
          <h2 className="font-bold text-2xl sm:text-3xl lg:text-4xl mb-6">
            こんな課題を抱えていませんか？
          </h2>
          <p>SDGs 共創 Lodgeでは、以下のような課題を解決できます。</p>
        </div>

        <FacedSection />
      </section>

      {/* メッセージ */}
      <MessageSection onClick={transferFormPage} />

      {/* 5ステップ */}
      <section className="mx-auto w-11/12 lg:max-w-3xl py-20">
        <div ref={fiveStepRef} />
        <div className="mb-16">
          <p className="text-sm mb-4">Realization steps</p>
          <h2 className="font-bold text-2xl sm:text-3xl lg:text-4xl mb-4">
            共創を実現する5つのステップ
          </h2>
          <p>
            SDGs 共創
            Lodgeでは、企業のプレスリリースやクチコミから得られる情報を活用し、課題解決と新規事業創出をサポートします。次の4つのステップを通じて、共創を加速し、持続可能な未来を築く道を明らかにします。
          </p>
        </div>

        <FiveSteps />
      </section>

      {/* シナリオ作成事例 */}
      <section className="w-11/12 max-w-7xl mx-auto py-20">
        <div className="max-w-2xl mb-16">
          <p className="text-sm mb-4">Scenario sample</p>
          <h2 className="text-2xl sm:text-3xl lg:text-4xl font-bold mb-4">
            シナリオ作成事例{' '}
          </h2>
          <h3 className="text-xl sm:text-2xl lg:text-3xl font-bold leading-tight mb-4">
            大手製造業とスタートアップの
            <br />
            コラボレーションによる
            <br />
            再生可能エネルギー製品の開発
          </h3>
          <p className="font-bold mb-4">背景・課題</p>
          <p>
            大手製造業の企業Aは、カーボンニュートラルを目指し、再生可能エネルギー分野での新規事業を模索していました。一方、スタートアップ企業Bは、革新的な太陽光パネル技術を開発していましたが、資金や市場へのアクセスに課題を抱えていました。
          </p>
        </div>
        <ScenarioFlow />
      </section>

      {/* One Team */}
      <section
        className="bg-cover bg-center py-20"
        style={{
          backgroundImage: `url(${OneteamBG})`,
        }}
      >
        <div className="w-11/12 max-w-7xl mx-auto text-center text-white">
          <div ref={visionRef} />
          <h3 className="text-2xl sm:text-3xl lg:text-4xl font-bold mb-8 leading-tight">
            One Teamで目指す
            <br />
            持続可能な未来
          </h3>
          <div>
            <p className="mb-8">地球は深刻な危機に瀕しております。</p>
            <p className="mb-8">
              気温上昇を産業革命以前に比べて1.5℃に抑えなければ、
              <br />
              地球のレジリエンス（回復力）が喪失されます。
            </p>
            <p className="mb-8">
              また、2022年2月24日以降、世界の緊張感はますます高まり、
              <br />
              戦火が世界へ広がるかもしれません。
            </p>
            <p className="mb-8">
              株式会社 The Lodgesは”SDGs”という
              <br />
              人類が初めて価値観を共有する奇跡的な出来事に対し、
              <br />
              可能性を開拓することを目的に事業を展開しております。
            </p>
            <p className="mb-8">
              第一弾であるSDGs PR Lodgeは
              <br />
              企業・団体の情報のプラットフォームとして
              <br />
              第二弾であるSDGs クチコミ Lodgeは
              <br />
              枠組を越えた話し合いの場のプラットフォームとして
              <br />
              そして、今回のSDGs 共創 Lodgeは
              <br />
              企業の課題解決としてのプラットフォームとして、
              <br />
              事業を展開しております。
            </p>
            <p className="mb-8">
              テレビやネットで映っている悲しき光景は、
              <br />
              今この時も地球上で起こっている現実です。
              <br />
              それらの出来事から、私たちの生活が影響を受けているように、
              <br />
              遠いあの地で起きている出来事も
              <br />
              ここ日本からもポジティブな影響を与えることができるかもしれません。
              <br />
              そのわずかな可能性に対して、私たちは行動を起こす責任があります。
            </p>
            <p className="mb-8">
              株式会社 The Lodgesは、企業や個人が具体的なアクションを起こし、
              <br />
              共に持続可能な未来を創り出すためのプラットフォームです。
            </p>
            <p className="mb-8">
              人類が繁栄を続けるために、避けて通れないこの難題に対して、
              <br />
              皆でスクラムを組み、One Teamになることを目指しております。
            </p>
            <p className="mb-8">
              株式会社 The Lodges
              <br />
              長澤 奏喜
            </p>
          </div>
        </div>
      </section>

      {/* メッセージ */}
      <MessageSection onClick={transferFormPage} />

      {/* フッター */}
      <footer className="bg-main-dimgray w-full py-20">
        <div className="w-11/12 mx-auto border border-white py-12 px-4 md:px-8 md:flex justify-between text-white md:mb-0 mb-8">
          {/* 左 */}
          <div>
            <img src={LogoWhite} className="w-40 block mb-12" />
            <div className="mb-8 hidden md:block">
              <p className="text-sm font-bold">Address:</p>
              <p className="text-sm">{ADDRESS}</p>
            </div>
            <div className="hidden md:block">
              <p className="text-sm font-bold">Contact:</p>
              <p className="text-sm">{TEL}</p>
              <p className="text-sm">{MAIL}</p>
            </div>
          </div>
          {/* 右 */}
          <div className="md:w-[250px]">
            <a
              className="block w-full mb-4"
              onClick={() => scrollToRef(topRef)}
            >
              TOP
            </a>
            <a
              className="block w-full mb-4"
              onClick={() => scrollToRef(featureRef)}
            >
              サービスの特徴
            </a>
            <a
              className="block w-full mb-4"
              onClick={() => scrollToRef(solutionRef)}
            >
              課題解決
            </a>
            <a
              className="block w-full mb-4"
              onClick={() => scrollToRef(fiveStepRef)}
            >
              共創の5ステップ
            </a>
            <a
              className="block w-full mb-8"
              onClick={() => scrollToRef(visionRef)}
            >
              ビジョン
            </a>
            <FlatButton
              className="w-full xs:w-fit"
              onClick={() => transferFormPage()}
            >
              今すぐ資料をダウンロード
            </FlatButton>
          </div>
          <div className="md:hidden pt-12">
            <div className="mb-4">
              <p className="font-bold">Address:</p>
              <p>{ADDRESS}</p>
            </div>
            <div>
              <p className="font-bold">Contact:</p>
              <p>{TEL}</p>
              <p>{MAIL}</p>
            </div>
          </div>
        </div>
      </footer>
      {process.env.REACT_APP_ENV !== 'production' && (
        <div className="fixed bottom-0 right-0 text-white p-2 w-screen z-50 text-center bg-slate-900/50">
          SDGs 共創
          Lodgeのテスト環境です。本番環境ではこの部分は表示されません。
        </div>
      )}
    </body>
  )

  return content
}

export default TopPage
