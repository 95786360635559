import React from 'react'

type Step = {
  no: number
  title: string
  description: string
}

/**
 * シナリオの流れ
 * @returns
 */
const FiveSteps = () => {
  const steps: Step[] = [
    {
      no: 1,
      title: '情報収集とアイデアの“種”発掘',
      description:
        'SDGsに特化したプレスリリースやクチコミサービスを通じ、企業の最新動向やユーザーのフィードバックを収集します。これにより、企業の課題や顧客ニーズが明確になり、新規事業の「種」となるアイデアを発掘します。',
    },
    {
      no: 2,
      title: 'AIによる企業マッチング',
      description:
        '収集された情報やアイデアを基に、AIを活用して最適な共創パートナーをマッチングします。AIは企業の強みや市場性、事業性を総合的に分析し、SDGsの目標達成に向けた最適な協力関係を提案します。このプロセスにより、企業同士が互いのリソースを最大限に活用し、持続可能なプロジェクトの具体化がスムーズに進むようサポートします。',
    },
    {
      no: 3,
      title: 'ビジネス案の作成',
      description:
        '集めた情報とアイデアを基に、企業間で協議し、市場性や事業性を考慮したビジネスモデルを作成します。企業の強みを活かしつつ、SDGsに基づいた共創プロジェクトの具体案を設計します。',
    },
    {
      no: 4,
      title: '検証とブラッシュアップ',
      description:
        'PoC（概念実証）を実施し、ビジネスモデルの実現可能性を確認します。また、一般ユーザーのクチコミから得られたフィードバックを活用し、課題の解決策をブラッシュアップし、さらに具体化します。',
    },
    {
      no: 5,
      title: '事業化と収益化',
      description:
        '事業計画を立案し、SDGs目標に基づくビジネスを正式に立ち上げます。事業展開やパートナーシップの構築を進め、収益化を図りながら、持続可能な成長を目指します。',
    },
  ]

  const stepContent = (step: Step) => (
    <div className="pl-6 sm:pl-12 relative border-l-4 border-black py-4">
      <p className="font-bold text-xl mb-3">STEP 0{step.no}.</p>
      <h3 className="font-bold text-2xl sm:text-3xl mb-4">{step.title}</h3>
      <p>{step.description}</p>
      <span className="block w-[24px] h-[24px] rounded-full bg-main-white absolute top-[18px] -left-[14px] z-10"></span>
      <span className="block w-[12px] h-[12px] rounded-full bg-main-black absolute top-[24px] -left-[8px] z-10"></span>
      <span className="block h-[100%] w-[2px] bg-main-black"></span>
    </div>
  )

  return <>{steps.map((s) => stepContent(s))}</>
}

export default FiveSteps
