import React from 'react'
import ChallengesGoverment from '../../images/challenges/goverment.png'
import ChallengesManagement from '../../images/challenges/management.png'
import ChallengesSmall from '../../images/challenges/small.png'
import ChallengesSustainable from '../../images/challenges/sustainable.png'

type ChallengeFaced = {
  image: string
  title: string
  issue: string
  solution: string
}

/**
 * 課題
 * @returns
 */
const FacedSection = () => {
  const challengeFaceds: ChallengeFaced[] = [
    {
      image: ChallengesManagement,
      title: '大企業の経営陣',
      issue:
        'SDGsやカーボンニュートラルなどの環境政策に関する規制と期待が高まっているが、社内での実施が指針にとどまり、具体的なアクションが伴っていない。',
      solution:
        '貴社のSDGsの方針に基づいた上で実践的な共創シナリオを提案し、具体的な成果に結びつける方法を提供します。',
    },
    {
      image: ChallengesSustainable,
      title: 'サステナブル室の担当者',
      issue:
        'サステナブルの取り組みが内部で十分に理解されず、社内の多様な部門との連携が取りにくい。基本的なSDGsの取り組みは行ったが、その後のアクションがわからない。',
      solution:
        'SDGs PR Lodgeのプレスリリースを活用し、企業内の成功事例を広く共有し、各部門間での意識と協働を促進。また、外部のアイディアや技術を取り入れ、持続可能な戦略の具体化を図ります。',
    },
    {
      image: ChallengesSmall,
      title: '中小企業の経営者',
      issue:
        '人やお金のリソースが限られており、サステナブルな取り組みができていない。',
      solution:
        '小規模な投資で大きなサステナブルな影響を生み出すシナリオを提供します。',
    },
    {
      image: ChallengesGoverment,
      title: '行政の関係者',
      issue:
        '地域社会のサステナブルな発展を推進するための公私パートナーシップの構築に課題を抱えている。',
      solution:
        '地方自治体と企業が連携し、地域固有の課題を解決するための共創プロジェクトを支援します。',
    },
  ]

  return (
    <div className="lg:flex flex-wrap">
      {challengeFaceds.map((ch) => (
        <>
          <div className="lg:w-1/2 xs:flex xs:px-4 mb-12 xs:mb-8">
            <div
              className="mx-auto mb-4 xs:mb-0 w-[160px] h-[160px] xs:w-[80px] xs:h-[80px] sm:w-[140px] sm:h-[140px] bg-cover bg-center rounded-full"
              style={{
                backgroundImage: `url(${ch.image})`,
              }}
            ></div>
            <div className="xs:w-[calc(100%-80px)] sm:w-[calc(100%-140px)] xs:pl-6">
              <h3 className="font-bold text-xl sm:text-2xl mb-6">{ch.title}</h3>
              <div className="mb-4 xs:mb-8">
                <p className="font-bold">■抱えている課題</p>
                <p className="leading-normal">{ch.issue}</p>
              </div>
              <div className="mb-4 xs:mb-8">
                <p className="font-bold mb-2">
                  ■SDGs 共創 Lodgeで解決できること
                </p>
                <p className="leading-normal">{ch.solution}</p>
              </div>
            </div>
          </div>
        </>
      ))}
    </div>
  )
}

export default FacedSection
