import React from 'react'
import Eco from '../../images/scenario/eco.png'
import Hands from '../../images/scenario/hands.png'
import City from '../../images/scenario/city.png'
import Panel from '../../images/scenario/panel.png'
import Meeting from '../../images/scenario/meeting.png'

type Flow = {
  image: string
  title: string
  description: string
  imagePosition: 'top' | 'bottom'
}

/**
 * シナリオの流れ
 * @returns
 */
const ScenarioFlow = () => {
  const flows: Flow[] = [
    {
      image: Eco,
      title: '情報収集とアイデアの“種”発掘',
      description:
        'SDGsに特化したプレスリリースを通じて、企業Aがカーボンニュートラル技術を探していることが明らかになりました。企業Bの技術が注目され、両社の共創の可能性が浮上しました。',
      imagePosition: 'top',
    },
    {
      image: Hands,
      title: 'AIによる企業マッチング',
      description:
        '収集された情報を基に、企業Aの事業性や市場性に最適なパートナーとして企業BがAIによって提案されました。両社のリソースを最大限に活用できるパートナーシップが形成されました。',
      imagePosition: 'bottom',
    },
    {
      image: City,
      title: 'ビジネス案の作成',
      description:
        '両社は協議の上、再生可能エネルギー製品を共同開発するビジネスモデルを策定しました。市場調査を実施し、両者の強みを活かしたビジネス案が作成されました。',
      imagePosition: 'top',
    },
    {
      image: Panel,
      title: '検証とブラッシュアップ',
      description:
        'PoC（概念実証）を通じて、企業Bの太陽光パネル技術の効果が検証され、企業Aの製造設備と統合するための調整が進められました。さらに、ユーザーのフィードバックを基に製品の改善が行われました。',
      imagePosition: 'bottom',
    },
    {
      image: Meeting,
      title: '事業化と収益化',
      description:
        '両社は共同で製品を開発し、企業Aの既存市場に製品を展開しました。企業Bは技術提供者としての地位を確立し、事業化と収益化に成功しました。',
      imagePosition: 'top',
    },
  ]

  const imageContentPC = (flow: Flow) => (
    <div className="pr-4 xl:pr-12 h-[200px] pb-2">
      <div
        className="w-full bg-center bg-cover h-full"
        style={{ backgroundImage: `url(${flow.image})` }}
      ></div>
    </div>
  )

  const textContentPC = (flow: Flow) => (
    <div className="pr-4 xl:pr-12 h-[200px]">
      <h4 className="font-bold mb-1 text-sm xl:text-base">{flow.title}</h4>
      <div className="leading-snug text-sm">{flow.description}</div>
    </div>
  )

  return (
    <>
      <p className="font-bold text-2xl mb-4">共創の流れ</p>
      <div className="lg:flex">
        {flows.map((flow, index) => (
          <>
            {/* PC表示 */}
            <div className="w-[20%] hidden lg:block" key={index}>
              {flow.imagePosition === 'top'
                ? imageContentPC(flow)
                : textContentPC(flow)}
              <div className="w-full h-[48px] relative">
                <span className="block w-[24px] h-[24px] rounded-full bg-main-white absolute top-[8px] -left-[6px] z-10"></span>
                <span className="block w-[12px] h-[12px] rounded-full bg-main-black absolute top-[14px] left-0 z-10"></span>
                <span className="w-full h-[4px] bg-main-black block relative top-[18px]"></span>
              </div>
              {flow.imagePosition === 'top'
                ? textContentPC(flow)
                : imageContentPC(flow)}
            </div>
            {/* SP表示 */}

            <div className="pl-6 sm:pl-12 relative border-l-4 border-black py-4 lg:hidden">
              <h3 className="font-bold text-lg sm:text-xl mb-4">
                {flow.title}
              </h3>
              <p className="mb-6">{flow.description}</p>
              <img
                src={flow.image}
                alt={flow.title}
                className="w-full max-w-md mb-8"
              />
              <span className="block w-[24px] h-[24px] rounded-full bg-main-white absolute top-[18px] -left-[14px] z-10"></span>
              <span className="block w-[12px] h-[12px] rounded-full bg-main-black absolute top-[24px] -left-[8px] z-10"></span>
              <span className="block h-[100%] w-[2px] bg-main-black"></span>
            </div>
          </>
        ))}
      </div>
    </>
  )
}

export default ScenarioFlow
