import React, { ReactNode } from 'react'

type FlatButtonProps = {
  children: ReactNode
  className?: string
  type?: 'button' | 'submit' | 'reset'
  onClick?: (e: React.MouseEvent) => void
}

const FlatButton: React.FC<FlatButtonProps> = ({
  children,
  onClick,
  className,
  type = 'button',
}) => {
  const defaultClassName = `block py-1 bg-main-green text-white px-4 text-center h-fit hover:opacity-50 transition-opacity duration-300`

  const combinedClassName = `${defaultClassName} ${className ?? ''}`

  return (
    <button onClick={onClick} type={type} className={combinedClassName}>
      {children}
    </button>
  )
}

export default FlatButton
