import NotFoundPage from './pages/error/not_found_page'
import TopPage from './pages/top/top_page'

type Route = {
  path: string
  element: React.FC
  auth?: boolean
}

export const routes: Route[] = [
  {
    path: '/',
    element: TopPage,
  },
  {
    path: '/*',
    element: NotFoundPage,
  },
]
