import { BrowserRouter, Routes, Route } from 'react-router-dom'
import React from 'react'
import ReactDOM from 'react-dom/client'
import ReactGA from 'react-ga4'
import { routes } from './route'
import './css/main.css'
import './css/tailwind.css'
import { BasicAuthProvider } from './providers/basic_auth_provider'

ReactGA.initialize(process.env.REACT_APP_GA_TAG_ID ?? 'TEST-TOKEN', {
  testMode: process.env.REACT_APP_ENV !== 'production',
})
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
document.documentElement.setAttribute('lang', 'ja')

const App = () => {
  return (
    <Routes>
      {routes.map((route, index) =>
        route.auth ? (
          <Route
            key={`auth-route-${index}`}
            path={route.path}
            element={<route.element />}
          />
        ) : (
          <Route
            key={`route-${index}`}
            path={route.path}
            element={<route.element />}
          />
        )
      )}
    </Routes>
  )
}

root.render(
  <>
    {/* <ProductionReadyProvider> */}
    {/* <BasicAuthProvider> */}
    <BrowserRouter basename="">
      <React.StrictMode>
        <App />
      </React.StrictMode>
    </BrowserRouter>
    {/* </BasicAuthProvider> */}
    {/* </ProductionReadyProvider> */}
  </>
)
